/* eslint-disable max-len */
import React from 'react';

interface INTCableProps {
    /**
     * A string representing the CSS class to be applied to the INTCableIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the INTCableIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the INTCableIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the INTCableIcon element.
     */
    width?: number | string;
}

/**
 * The `INTCable` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `INTCableProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the INTCableIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the INTCableIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the INTCableIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const INTCableComponent = <INTCable className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const INTCable = React.forwardRef<SVGSVGElement, INTCableProps>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54" fill={color1} />
            <g>
                <path d="M19.13 35.92c-9.26 0-16.79-7.53-16.79-16.79S9.88 2.35 19.13 2.35s16.79 7.53 16.79 16.79-7.53 16.79-16.79 16.79" />
                <path d="M24.52 23.1c-.57 1.97-3.77 2.79-7.14 1.82-3.38-.97-5.65-3.36-5.09-5.33.57-1.98 3.77-2.79 7.14-1.82 3.38.97 5.66 3.36 5.09 5.33" fill={color1} fillRule="evenodd" />
                <path d="M29.86 6.81s-7.68 2.7-11.45 5.67c-3.61 2.84-4.24 8.62-4.24 8.62s.68 1.51 3.38 2.31c1.72.51 3.14.37 3.94.2.45-.1.87-.51.87-.51s.37-3.84 2.65-6.07c3.06-2.98 8.97-4.5 8.97-4.5" fillRule="evenodd" />
                <path d="M29.86 6.81s-7.68 2.7-11.45 5.67c-3.61 2.84-4.24 8.62-4.24 8.62s.68 1.51 3.38 2.31c1.72.51 3.14.37 3.94.2.45-.1.87-.51.87-.51s.37-3.84 2.65-6.07c3.06-2.98 8.97-4.5 8.97-4.5" fill="none" stroke={color1} strokeMiterlimit="10" strokeWidth=".56" />
                <path d="M20.05 12.7c-1.3.83-3.06 4.24-3.06 4.24s2.67-3.56 3.73-3.8l-.67-.44Zm7.7-8.29S20.29 1.43 15.59 2.9C10.88 4.37 6.4 8.59 5.71 9.65c-.69 1.07-2.47 5.72-2.47 5.72L27.75 4.41Z" fill={color1} fillRule="evenodd" />
                <path d="M19.13 3.19c8.79 0 15.94 7.15 15.94 15.94s-7.15 15.94-15.94 15.94S3.19 27.92 3.19 19.13 10.34 3.19 19.13 3.19m0-1.69a17.63 17.63 0 1 0 0 35.25 17.63 17.63 0 0 0 0-35.25" fill={color1} />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

INTCable.displayName = 'INTCable';
INTCable.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'INTCable',
    width: 38.27
};