/* eslint-disable max-len */
import React from 'react';

interface THRUAxleProps {
    /**
     * A string representing the CSS class to be applied to the THRUAxleIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the THRUAxleIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the THRUAxleIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the THRUAxleIcon element.
     */
    width?: number | string;
}

/**
 * The `THRUAxle` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `THRUAxleProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the THRUAxleIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the THRUAxleIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the THRUAxleIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const THRUAxleComponent = <THRUAxle className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const THRUAxle = React.forwardRef<SVGSVGElement, THRUAxleProps>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54" fill={color1} />
            <g>
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="m31.96 11.63 3.89 7.67-3.89 7.34v-4.41h-1.62v-6.38h1.62v-4.22zm-25.65 0-3.9 7.67 3.9 7.34v-4.41h1.62v-6.38H6.31v-4.22zm23.36 3.57V8.62h-1.98v4.49l-2 2.32H12.62l-2.04-2.36V8.62H8.6v21.03h1.98v-4.54l1.96-2.27h13.15l2 2.31v4.5h1.98V15.2z" fill={color1} fillRule="evenodd" />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

THRUAxle.displayName = 'THRUAxle';
THRUAxle.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'THRUAxle',
    width: 38.27
};