/* eslint-disable max-len */
import React from 'react';

interface WHEELS29Props {
    /**
     * A string representing the CSS class to be applied to the WHEELS29Icon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the WHEELS29Icon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the WHEELS29Icon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the WHEELS29Icon element.
     */
    width?: number | string;
}

/**
 * The `WHEELS29` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `WHEELS29Props` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the WHEELS29Icon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the WHEELS29Icon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the WHEELS29Icon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const WHEELS29Component = <WHEELS29 className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const WHEELS29 = React.forwardRef<SVGSVGElement, WHEELS29Props>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="M19.13 35.92c-9.26 0-16.79-7.53-16.79-16.79S9.88 2.35 19.13 2.35s16.79 7.53 16.79 16.79-7.53 16.79-16.79 16.79" />
                <path d="M19.13 3.19c8.79 0 15.94 7.15 15.94 15.94s-7.15 15.94-15.94 15.94S3.19 27.92 3.19 19.13 10.34 3.19 19.13 3.19m0-1.69a17.63 17.63 0 1 0 0 35.25 17.63 17.63 0 0 0 0-35.25" fill={color1} />
                <path d="M17.07 19.92a5.5 5.5 0 0 1-.48 2.03c-.25.53-.53.82-.82.89l-7.13 1.59-.05.13-.03.12.05 1.36 8.6-1.92.13 3.25L5.6 29.99l-.22-5.56c0-.28.03-.58.11-.88.08-.3.17-.59.29-.87.11-.28.24-.51.38-.71.14-.19.27-.32.39-.36l7.26-1.62c.05-.12.08-.21.07-.28l-.02-.42c-.03-.3-.07-.46-.1-.5l-8.01 1.79-.13-3.32 9.61-2.15c.15-.03.32.02.52.16s.38.34.56.58a4 4 0 0 1 .44.85c.12.33.19.65.2.97l.1 2.24Zm13.64 2.03a3.74 3.74 0 0 1-.73 2.26c-.13.16-.26.28-.37.35h.01l-.07.06-.1.04L19 27l-.13-3.25 8.6-1.92c.05-.05.06-.08.03-.07v-.03l-.05-1.17-7.99 1.79c-.16.04-.33-.02-.5-.17a2.47 2.47 0 0 1-.47-.57 4.6 4.6 0 0 1-.36-.74c-.1-.26-.16-.49-.17-.68l-.13-3.25c0-.22.03-.47.11-.76a6.48 6.48 0 0 1 .71-1.55c.13-.2.24-.31.32-.33l9.91-2.22c.14-.03.3 0 .47.07.17.08.34.21.49.38a2.52 2.52 0 0 1 .56 1.6l.31 7.84Zm-9.47-3.13 6.11-1.37-.05-.94c0-.24-.06-.45-.17-.62s-.23-.24-.37-.21l-5.63 1.26c-.06.01-.12.13-.2.34a1.72 1.72 0 0 0 .03 1.26c.08.21.17.31.28.28" fill={color1} />
                <path d="m30.53 12.81-1.93.44-.12-4.29 1.92-.43.13 4.28zm2.39-.53-1.93.43-.13-4.28L32.79 8l.13 4.28z" fill={color1} />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

WHEELS29.displayName = 'WHEELS29';
WHEELS29.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'WHEELS29',
    width: 38.27
};