/* eslint-disable max-len */
import React from 'react';

interface STEMAdjProps {
    /**
     * A string representing the CSS class to be applied to the STEMAdjIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A string representing the color to be applied.
     */
    color2?: string;
    /**
     * A number or string representing the height of the STEMAdjIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the STEMAdjIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the STEMAdjIcon element.
     */
    width?: number | string;
}

/**
 * The `STEMAdj` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `STEMAdjProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the STEMAdjIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the STEMAdjIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the STEMAdjIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const STEMAdjComponent = <STEMAdj className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const STEMAdj = React.forwardRef<SVGSVGElement, STEMAdjProps>((props, ref) => {
    const {className, color1, color2, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54" fill={color1} />
            <g>
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="M19.13 35.74C9.9 35.74 2.4 28.23 2.4 19.01S9.91 2.28 19.13 2.28s16.73 7.51 16.73 16.73-7.51 16.73-16.73 16.73" fill={color1} />
                <path d="M19.13 3.19A15.96 15.96 0 0 0 3.19 19.13c0 3.62 1.22 6.96 3.26 9.64v-4.68h8.92v10.53a15.96 15.96 0 0 0 19.71-15.49c0-8.79-7.15-15.94-15.95-15.94" />
                <path d="M18.9 6.71s-2.49 4.28-3.62 5.2c-1.13.92-6.68 2.75-6.68 2.75l2.6 5.23 1.16 6.53s1.35-.07 2.82-1.48c1.47-1.41 3.29-3.78 5.59-5.67 2.5-2.05 4.85-2.14 5.68-2.5.83-.36 2.28-1.55 2.28-1.55l-7.99-9.76-1.85 1.25Z" fill={color1} />
                <path d="M17.1 20.38a6.19 6.19 0 1 1-12.38 0 6.19 6.19 0 0 1 12.38 0" fill={color1} />
                <circle cx="10.9" cy="20.38" fill="none" r="6.19" stroke={color2} strokeMiterlimit="10" strokeWidth=".45" />
                <path d="M24.07 16.69a6.05 6.05 0 1 1 .01-12.1 6.05 6.05 0 0 1-.01 12.1" />
                <path d="M24.07 5.11a5.56 5.56 0 1 1-.01 11.11 5.56 5.56 0 0 1 .01-11.11m0-.98a6.52 6.52 0 1 0 0 13.05 6.52 6.52 0 0 0 0-13.05" fill={color1} />
                <path d="M12.08 14.18a6.19 6.19 0 0 1 4.97 7.21" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".23" />
                <path d="M12.18 20.38a1.27 1.27 0 1 1-2.54 0 1.27 1.27 0 0 1 2.54 0" />
                <path d="m28.99 25.9 1.71 1.11c.5-.73.94-1.5 1.31-2.32l-2.1-.49c-.26.6-.57 1.16-.92 1.69m-3.46 3.38.89 1.88c.76-.49 1.49-1.04 2.15-1.65l-1.33-1.52c-.52.48-1.1.92-1.71 1.3m-1.34 3.02.04-.01-.69-2.06-.06.02v-.02l-1.13.36-.36-1.62-4.54 4.14 5.88 1.95-.47-2.13 1.37-.49-.04-.12Zm8.56-16.39-1.69 2.37-1.88 2.63 1.6.16c-.06.39-.12.78-.22 1.16l1.62.34.7.15.33-1.41 2.17.22-2.63-5.61Z" fill={color1} />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

STEMAdj.displayName = 'STEMAdj';
STEMAdj.defaultProps = {
    className: '',
    color1: '#fff',
    color2: '#000',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'STEMAdj',
    width: 38.27
};