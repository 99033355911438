/* eslint-disable max-len */
import React from 'react';

interface HYBRIDProps {
    /**
     * A string representing the CSS class to be applied to the HYBRIDIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A string representing the color to be applied.
     */
    color2?: string;
    /**
     * A number or string representing the height of the HYBRIDIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the HYBRIDIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the HYBRIDIcon element.
     */
    width?: number | string;
}

/**
 * The `HYBRID` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `HYBRIDProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the HYBRIDIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the HYBRIDIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the HYBRIDIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const HYBRIDComponent = <HYBRID className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const HYBRID = React.forwardRef<SVGSVGElement, HYBRIDProps>((props, ref) => {
    const {className, color1, color2, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.14s7.87 17.54 17.54 17.54" fill={color1} />
            <g>
                <path d="M34.2 19.13a15.51 15.51 0 1 1-31.02-.02 15.51 15.51 0 0 1 31.02.03" />
                <path d="M18.69 3.63c-8.55 0-15.51 6.96-15.51 15.51s6.95 15.51 15.51 15.51S34.2 27.69 34.2 19.14 27.24 3.63 18.69 3.63" />
                <path d="M26.82 16.42 11.06 23.7a7.4 7.4 0 0 0 4.19 3.65c1.78.6 3.59.47 5.45-.39a7.1 7.1 0 0 0 4.19-6.61l2.54-1.17c.09 2.3-.35 4.31-1.33 6.01a9.37 9.37 0 0 1-4.34 3.89 9.52 9.52 0 0 1-7.7.26 9.94 9.94 0 0 1-5.65-5.3 9.9 9.9 0 0 1-.47-7.78 9.8 9.8 0 0 1 5.39-5.65 9.17 9.17 0 0 1 7.67-.26c2.49 1.04 4.4 3.01 5.74 5.9l.08.16Zm-3.38-.81c-1.32-1.75-2.77-2.85-4.36-3.31a7.18 7.18 0 0 0-5.11.57 6.84 6.84 0 0 0-3.57 3.57 7.52 7.52 0 0 0-.18 5.27l13.22-6.11Z" fill={color1} />
                <path d="M26.82 16.42 11.06 23.7a7.4 7.4 0 0 0 4.19 3.65c1.78.6 3.59.47 5.45-.39a7.1 7.1 0 0 0 4.19-6.61l2.54-1.17c.09 2.3-.35 4.31-1.33 6.01a9.37 9.37 0 0 1-4.34 3.89 9.52 9.52 0 0 1-7.7.26 9.94 9.94 0 0 1-5.65-5.3 9.9 9.9 0 0 1-.47-7.78 9.8 9.8 0 0 1 5.39-5.65 9.17 9.17 0 0 1 7.67-.26c2.49 1.04 4.4 3.01 5.74 5.9l.08.16Zm-3.38-.81c-1.32-1.75-2.77-2.85-4.36-3.31a7.18 7.18 0 0 0-5.11.57 6.84 6.84 0 0 0-3.57 3.57 7.52 7.52 0 0 0-.18 5.27l13.22-6.11Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".5" />
                <path d="m22.96 15.61-1.02 6.06s-.4 1.37.36 1.74c.77.36 5.86 1.87 5.86 1.87s1.23.41 1.77-.9c.45-1.09 1.98-4.22 2.62-5.71.13-.31.54-1.1.21-1.58-.32-.46-1.04-.72-1.04-.72l1.31-4.21s.09-.83-.71-1.08c-.88-.28-1.21.5-1.21.5l-1.43 4.04-2.44-.7 1.4-4.08s.1-.82-.85-1.13c-.81-.26-1.25.42-1.25.42l-1.44 4.26s-1.24-.26-1.54-.09c-.44.24-.6 1.34-.6 1.34" fill={color1} />
                <path d="m22.96 15.61-1.02 6.06s-.4 1.37.36 1.74c.77.36 5.86 1.87 5.86 1.87s1.23.41 1.77-.9c.45-1.09 1.98-4.22 2.62-5.71.13-.31.54-1.1.21-1.58-.32-.46-1.04-.72-1.04-.72l1.31-4.21s.09-.83-.71-1.08c-.88-.28-1.21.5-1.21.5l-1.43 4.04-2.44-.7 1.4-4.08s.1-.82-.85-1.13c-.81-.26-1.25.42-1.25.42l-1.44 4.26s-1.24-.26-1.54-.09c-.44.24-.6 1.34-.6 1.34Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".28" />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.14s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

HYBRID.displayName = 'HYBRID';
HYBRID.defaultProps = {
    className: '',
    color1: '#fff',
    color2: '#000',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'HYBRID',
    width: 38.27
};