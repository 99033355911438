/* eslint-disable max-len */
import React from 'react';

interface WHEELS28Props {
    /**
     * A string representing the CSS class to be applied to the WHEELS28Icon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A string representing the color to be applied.
     */
    color2?: string;
    /**
     * A number or string representing the height of the WHEELS28Icon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the WHEELS28Icon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the WHEELS28Icon element.
     */
    width?: number | string;
}

/**
 * The `WHEELS28` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `WHEELS28Props` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the WHEELS28Icon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the WHEELS28Icon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the WHEELS28Icon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const WHEELS28Component = <WHEELS28 className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const WHEELS28 = React.forwardRef<SVGSVGElement, WHEELS28Props>((props, ref) => {
    const {className, color1, color2, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g>
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="M19.13 3.19c-8.79 0-15.94 7.15-15.94 15.94s7.15 15.94 15.94 15.94 15.94-7.15 15.94-15.94S27.92 3.19 19.13 3.19" />
                <path d="M16.49 19.19a6.5 6.5 0 0 1-.39 2.19c-.23.57-.49.89-.77.95l-6.94 1.65-.05.14c-.02.04-.02.08-.02.13l.1 1.48 8.38-1.99.25 3.52-11.43 2.71-.43-6.03c-.01-.31.01-.62.07-.95.07-.33.15-.64.25-.94.1-.3.22-.55.35-.76.13-.21.25-.34.36-.39l7.07-1.68a.65.65 0 0 0 .06-.3l-.03-.46c-.05-.32-.09-.5-.11-.55l-7.8 1.85-.26-3.59 9.36-2.22c.15-.03.32.02.52.18s.39.37.57.64a4.5 4.5 0 0 1 .71 1.99l.18 2.43Z" fill={color1} />
                <path d="m16.36 13.24 9.77-2.32a1 1 0 0 1 .59.08 2.5 2.5 0 0 1 1.26 1.08c.17.28.26.6.28.95l.14 2.58c0 .17-.04.44-.14.82-.1.38-.21.72-.31 1a12.02 12.02 0 0 1 .4 1l.14.43.07.22.13 2.55c.02.35-.04.7-.17 1.05a4.22 4.22 0 0 1-1.13 1.64c-.22.18-.41.29-.56.33l-9.77 2.32c-.16.04-.35.02-.58-.06a2.25 2.25 0 0 1-.68-.39 2.76 2.76 0 0 1-.57-.69 2.03 2.03 0 0 1-.27-.94l-.13-2.55a11.6 11.6 0 0 1 .25-1.34l.14-.54a6.59 6.59 0 0 1-.55-1.59l-.14-2.58c-.02-.35.04-.71.17-1.06a3.82 3.82 0 0 1 1.13-1.64c.22-.2.4-.31.56-.35m2.44 9.92 5.86-1.39a.78.78 0 0 0 .48-.33c.11-.17.15-.43.13-.78v-.11c0-.12 0-.25-.02-.39a1.02 1.02 0 0 0-.07-.34l-6.99 1.66c-.02.1-.02.22-.01.36l.03.39v.11c.01.35.07.59.18.71.1.12.25.16.42.11m-.34-6.98a.6.6 0 0 0-.4.31c-.09.17-.13.42-.11.78l.02.48c0 .15.03.26.07.32l6.96-1.65c.02-.08.04-.19.03-.35 0-.16 0-.32-.02-.48-.02-.35-.09-.59-.21-.7-.12-.12-.28-.15-.49-.1l-5.86 1.39Z" fill={color1} />
                <path d="m16.36 13.24 9.77-2.32a1 1 0 0 1 .59.08 2.5 2.5 0 0 1 1.26 1.08c.17.28.26.6.28.95l.14 2.58c0 .17-.04.44-.14.82-.1.38-.21.72-.31 1a12.02 12.02 0 0 1 .4 1l.14.43.07.22.13 2.55c.02.35-.04.7-.17 1.05a4.22 4.22 0 0 1-1.13 1.64c-.22.18-.41.29-.56.33l-9.77 2.32c-.16.04-.35.02-.58-.06a2.25 2.25 0 0 1-.68-.39 2.76 2.76 0 0 1-.57-.69 2.03 2.03 0 0 1-.27-.94l-.13-2.55a11.6 11.6 0 0 1 .25-1.34l.14-.54a6.59 6.59 0 0 1-.55-1.59l-.14-2.58c-.02-.35.04-.71.17-1.06a3.82 3.82 0 0 1 1.13-1.64c.22-.2.4-.31.56-.35Zm2.44 9.92 5.86-1.39a.78.78 0 0 0 .48-.33c.11-.17.15-.43.13-.78v-.11c0-.12 0-.25-.02-.39a1.02 1.02 0 0 0-.07-.34l-6.99 1.66c-.02.1-.02.22-.01.36l.03.39v.11c.01.35.07.59.18.71.1.12.25.16.42.11Zm-.34-6.98a.6.6 0 0 0-.4.31c-.09.17-.13.42-.11.78l.02.48c0 .15.03.26.07.32L25 16.42c.02-.08.04-.19.03-.35 0-.16 0-.32-.02-.48-.02-.35-.09-.59-.21-.7-.12-.12-.28-.15-.49-.1l-5.86 1.39Z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".27" />
                <path d="m29.56 11.7-2.11.49-.18-4.7L29.38 7l.18 4.7z" fill={color1} />
                <path d="m29.56 11.7-2.11.49-.18-4.7L29.38 7l.18 4.7z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".28" />
                <path d="m31.57 11.23-2.11.49-.18-4.7 2.11-.49.18 4.7z" fill={color1} />
                <path d="m31.57 11.23-2.11.49-.18-4.7 2.11-.49.18 4.7z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth=".28" />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.13s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

WHEELS28.displayName = 'WHEELS28';
WHEELS28.defaultProps = {
    className: '',
    color1: '#fff',
    color2: '#000',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'WHEELS28',
    width: 38.27
};