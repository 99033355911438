/* eslint-disable max-len */
import React from 'react';

interface DROPSeatProps {
    /**
     * A string representing the CSS class to be applied to the DROPSeatIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A string representing the color to be applied.
     */
    color2?: string;
    /**
     * A number or string representing the height of the DROPSeatIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the DROPSeatIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the DROPSeatIcon element.
     */
    width?: number | string;
}

/**
 * The `DROPSeat` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `DROPSeatProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the DROPSeatIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.color2    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the DROPSeatIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the DROPSeatIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const DROPSeatComponent = <DROPSeat className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const DROPSeat = React.forwardRef<SVGSVGElement, DROPSeatProps>((props, ref) => {
    const {className, color1, color2, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.14s7.87 17.54 17.54 17.54" fill={color1} />
            <g>
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="M19.13 35.92c-9.26 0-16.79-7.53-16.79-16.79S9.88 2.35 19.13 2.35s16.79 7.53 16.79 16.79-7.53 16.79-16.79 16.79" />
                <path d="M19.13 4.72c7.95 0 14.42 6.47 14.42 14.42s-6.47 14.42-14.42 14.42S4.71 27.09 4.71 19.14 11.18 4.72 19.13 4.72m0-1.53a15.94 15.94 0 1 0 0 31.88 15.94 15.94 0 0 0 0-31.88" />
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="m16.09 26.74 13.24 4.65 5.45-12.1-5.21 2.08-4.22-10.81-7.93 3.09 4.24 10.86-5.57 2.23z" fill={color1} fillRule="evenodd" />
                <path d="M25.56 10.9 12.43 5.49 6.76 18.4l5.84-2.34 4.05 10.41 7.63-2.97-4.08-10.45 5.36-2.15z" fill={color1} fillRule="evenodd" />
                <path d="M25.56 10.9 12.43 5.49 6.76 18.4l5.84-2.34 4.05 10.41 7.63-2.97-4.08-10.45 5.36-2.15z" fill="none" stroke={color2} strokeMiterlimit="10" strokeWidth="1.01" />
                <path d="M14.82 25.54h1.71v1.74h-1.71z" />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.14s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

DROPSeat.displayName = 'DROPSeat';
DROPSeat.defaultProps = {
    className: '',
    color1: '#fff',
    color2: '#000',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'DROPSeat',
    width: 38.27
};