/* eslint-disable max-len */
import React from 'react';

interface DI2ReadyProps {
    /**
     * A string representing the CSS class to be applied to the DI2ReadyIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the DI2ReadyIcon element.
     */
    height?: number | string;
    /**
     * A number representing the stroke width of the DI2ReadyIcon element.
     */
    strokeWidth?: number;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the DI2ReadyIcon element.
     */
    width?: number | string;
}

/**
 * The `DI2Ready` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `DI2ReadyProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the DI2ReadyIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the DI2ReadyIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the DI2ReadyIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const DI2ReadyComponent = <DI2Ready className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const DI2Ready = React.forwardRef<SVGSVGElement, DI2ReadyProps>((props, ref) => {
    const {className, color1, height, strokeWidth, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 38.27 38.27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.14s7.87 17.54 17.54 17.54" fill={color1} />
            <g>
                <path d="M35.08 19.13a15.94 15.94 0 1 1-31.86 0 15.94 15.94 0 0 1 31.85 0" />
                <path d="M7.37 21.87h6.61c.53 0 1.87.28 2.47 1.13.6.85.6 1.3.6 1.75 0 .58-.17 2.04-1.37 2.61-.89.42-1.61.52-1.96.51H7.36v-3.76H9.3v2.19h4.4s1.57.04 1.57-1.38-1.11-1.49-1.57-1.49l-6.33-.01v-1.54Zm10.9-.03h2.04v1.51h-2.04zm0 2.28h2.04v3.75h-2.04zm3.4-2.27h6.9s1.15.14 1.67.5c.51.37.66 1.01.66 1.29s0 .88-.61 1.35c-.57.44-1.14.55-1.69.55h-3.69s-.66.06-.99.2c-.48.21-.45.53-.45.53h7.34v1.6h-9.39v-.64c0-.47.28-1.68.8-2.16.54-.5 1.25-.95 2.34-.95h4.03c.13 0 .47-.03.47-.39s-.38-.36-.5-.36h-6.88v-1.51Zm-8.69-7.08L16 16.44s.67-.88 1.69-2.15c1.03-1.27 2.33-2.54 3.11-3.33.77-.79 1.84-1.76 2.55-2.31.71-.56 1.77-1.29 2.16-1.48.39-.19.77-.22.99 0 .21.21-.06.58-.24.75-.17.17-2.51 2.53-3.6 3.81a31.7 31.7 0 0 0-2.88 3.98 66.48 66.48 0 0 0-1.98 3.32c-.43.79-.58 1.13-.66 1.31-.08.17-.36.36-.6.11-.24-.26-3.73-4.4-4.09-4.81-.36-.41-.45-.75-.28-.9.17-.15.23-.27.81.04" fill={color1} />
            </g>
            <path d="M19.13 36.67c9.67 0 17.54-7.87 17.54-17.54S28.8 1.59 19.13 1.59 1.59 9.46 1.59 19.14s7.87 17.54 17.54 17.54Z" fill="none" stroke={color1} strokeWidth={strokeWidth} />
        </svg>
    );
});

DI2Ready.displayName = 'DI2Ready';
DI2Ready.defaultProps = {
    className: '',
    color1: '#fff',
    height: 38.27,
    strokeWidth: 3.19,
    testId: 'DI2Ready',
    width: 38.27
};